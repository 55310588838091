<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>Collaborative instrument category</h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard</router-link>
                  \ Collaborative instrument category
                </div>
              </div>
              <div class="breadcrumb-right">
                <div class="card-toolbar">
                  <v-btn @click="createCollaborativeInstrumentCategory()" class="mt-4 btn btn-primary" style="color: #fff">
                    <v-icon small elevation="2" outlined>fas fa-plus</v-icon>&nbsp;
                    Add collaborative instrument category
                  </v-btn>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start">

              <div class="row">
                <v-col cols="12" sm="6" md="3">
                  <v-text-field
                      label="Collab category"
                      v-model="search.info"
                      outlined
                      dense
                      v-on:keyup.enter="searchCategory()"
                      @input="search.info = $event !== null ? $event : ''"
                      clearable
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="3">
                  <v-select
                      :items="search_statuses"
                      v-model="search.status"
                      label="Status"
                      item-text="name"
                      item-value="value"
                      outlined dense
                      v-on:keyup.enter="searchCategory()"
                      clearable
                  ></v-select>
                </v-col>

                <v-col cols="12" md="6">
                  <v-btn
                      @click.prevent="searchCategory()"
                      class="btn btn-primary w-35 float-right"
                      :loading="loading"
                      dark
                  >
                    <v-icon small elevation="2" outlined>fas fa-search</v-icon>&nbsp;
                    Search
                  </v-btn>
                </v-col>
              </div>

            </div>
            <div class="table-responsive">

              <v-skeleton-loader
                  type="table-thead"
                  v-if="loading"
              >
              </v-skeleton-loader>

              <v-skeleton-loader
                  v-if="loading"
                  type="table-row-divider@25"
              >
              </v-skeleton-loader>

              <table class="table">
                <thead>
                <tr class="px-3">
                  <th class="px-3">Collab Category</th>
                  <th class="px-3">Status</th>
                  <th class="pr-3 text-center">Options</th>
                </tr>
                </thead>

                <template>
                    <tr v-show="categories.length" v-for="category in categories">
                      <td class="px-3">
                        <a @click="editCategory(category)"
                           class="text-primary font-weight-bolder text-hover-primary mb-1">
                           {{ category.title }}
                        </a>
                      </td>

                      <td class="px-3">
                          <span class="badge"
                                v-bind:class="{ 'badge-success': category.is_active, 'badge-danger': !category.is_active }"
                          >{{ category.is_active ? 'Active' : 'Inactive' }}</span>
                      </td>
                      <td class="pr-0 text-center">
                        <template>
                          <b-dropdown
                              size="sm"
                              variant="link"
                              toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                              no-caret
                              right
                              no-flip
                          >
                            <template v-slot:button-content>
                              <i class="ki ki-bold-more-hor"></i>
                            </template>
                            <!--begin::Navigation-->
                            <div class="navi navi-hover min-w-md-250px">
                              <b-dropdown-text tag="div" class="navi-item" >
                                <a class="navi-link" @click="editCategory(category)">
                                  <span class="navi-icon">
                                      <i class="fas fa-edit"></i>
                                  </span>
                                  <span class="navi-text">Edit</span>
                                </a>
                              </b-dropdown-text>
                              <b-dropdown-text tag="div" class="navi-item">
                                <a class="navi-link" @click.prevent="deleteCategory(category.id)">
                                  <span class="navi-icon">
                                     <i class="fas fa-trash"></i>
                                  </span>
                                  <span class="navi-text">Delete</span>
                                </a>
                              </b-dropdown-text>
                            </div>
                            <!--end::Navigation-->
                          </b-dropdown>
                        </template>
                      </td>
                    </tr>
                    <tr v-if="categories.length == 0">
                      <td  class="px-3 text-center" colspan="10">Items not found.</td>
                    </tr>

                </template>
              </table>
              <b-pagination
                  v-if="categories.length > 0"
                  class="pull-right mt-7"
                  @input="getAllCategories"
                  v-model="page"
                  :total-rows="total"
                  :per-page="perPage"
                  first-number
                  last-number
                  :disabled="loading"
              ></b-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
    <create-and-update ref="create-and-update" @refresh="getAllCategories"></create-and-update>
  </v-app>
</template>
<script>
import CollaborativeInstrumentCategoryService from "@/services/product/collaborative-instrument-category/CollaborativeInstrumentCategoryService";
import CreateAndUpdate from "./CreateAndUpdate";
const collaborativeInstrumentCategory=new CollaborativeInstrumentCategoryService();
export default {
  components: {
    CreateAndUpdate
  },
  data(){
    return{
        categories:[],
        total: null,
        perPage: null,
        page: null,
        loading:false,
        search: {
            info: '',
            status: '',
        },
        search_statuses: [
        {name: 'Active', value: 1},
        {name: 'Inactive', value: 0},
      ],
    }
  },
  methods: {
    createCollaborativeInstrumentCategory(){
      this.$refs['create-and-update'].createCategory();
    },
    searchCategory(){
      this.getAllCategories();
    },

    getAllCategories() {
      this.loading = true;
      collaborativeInstrumentCategory
          .paginate(this.search, this.page)
          .then(response => {
            this.categories = response.data.data;
            this.page = response.data.meta.current_page;
            this.total = response.data.meta.total;
            this.perPage = response.data.meta.per_page;
            this.loading = false;
          })
          .catch((err) => {
            this.loading = false;
          });
    },
    editCategory(item){
      this.$refs['create-and-update'].editCategory(item);
    },
    deleteCategory(item) {
      this.$confirm({
        message: `Are you sure? `,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            collaborativeInstrumentCategory
                .delete(item)
                .then((response) => {
                  this.getAllCategories();
                  this.$snotify.success("Category deleted");
                })
                .catch((err) => {
                  this.$snotify.error("Oops something went wrong");
                });
          }
        },
      });
    },
  },
  mounted() {
    this.getAllCategories();
  }
}
</script>