<template>
  <v-app>
    <v-dialog
        v-model="dialog"
        max-width="700"
        scrollable
    >
      <v-card>
        <v-toolbar
            dark>
          <v-card-title>

            <span>{{ title }} collaborative instrument category</span>
            <hr>
          </v-card-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="closeDialog">
              <i class="fa fa-close"></i>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-text-field
                    outlined
                    dense
                    :error="$v.category.title.$error"
                    v-model="category.title"
                >
                  <template v-slot:label>
                    Collab category title <span class="text-danger">*</span>
                  </template>
                </v-text-field>
                <span class="text-danger" v-if="errors.title">{{ errors.name[0] }}</span>
                <span class="text-danger" v-if="$v.category.title.$error">This information is required</span>

              </v-col>
              <v-col cols="6" md="4">
                Status
                <v-switch
                    v-model="category.is_active"
                    :label="category.is_active?'Active':'Inactive'"
                    color="primary"

                ></v-switch>
              </v-col>


            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
             x-large
             text
              @click="dialog = false">
            Cancel
          </v-btn>
          <v-btn
              dark
              x-large
              :loading="loading"
              @click="createOrUpdate()"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>
<script>
import CollaborativeInstrumentCategoryService from "@/services/product/collaborative-instrument-category/CollaborativeInstrumentCategoryService";
import {required} from "vuelidate/lib/validators";
const collaborativeInstrumentCategory=new CollaborativeInstrumentCategoryService();
export default {
  validations: {
    category: {
      title: {required},
    }
  },
  data() {
    return {
      title: "",
      dialog: false,
      category: {
        title:'',
        is_active:1,
      },
      loading:false,
      errors: [],
      edit: false,
    }
  },
  methods:{
    createOrUpdate(){
      this.$v.$touch()
      if (this.$v.$error) {
        setTimeout(() => {
          this.$v.$reset()
        }, 3000);
      } else {
        if (!this.edit) this._create(this.category);
        else this.update(this.category);
      }
    },
    openDialog() {
      this.$v.$reset();
      this.dialog = true;
    },
    resetForm() {
      this.openDialog();
      this.category = {
        title:'',
        is_active:1,
      }

      this.$v.$reset();
    },
    createCategory() {
      this.resetForm();
      this.openDialog();
      this.edit = false;
      this.title = "Add";
    },
    editCategory(item) {
      this.openDialog();
      this.edit = true;
      this.category = item;
      this.title = "Edit";
    },
    closeDialog() {
      this.dialog = false;
      this.errors = [];
    },
    _create: function (data) {
      this.loading = true;
      collaborativeInstrumentCategory
          .create(data)
          .then(response => {
            this.$snotify.success("Category added");
            this.resetForm();
            this.closeDialog();
            this.loading = false;
            this.$emit('refresh');
          })
          .catch((err) => {
            this.loading = false;
            this.errors = err.errors;
            this.$snotify.error("Oops something went wrong");
          });
    },
    update: function (data) {
      this.loading = true;
      collaborativeInstrumentCategory
          .update(this.category.id, data)
          .then(response => {
            this.$snotify.success("Category updated");
            this.resetForm();
            this.closeDialog();
            this.loading = false;
            this.$emit('refresh');
          })
          .catch((err) => {
            this.loading = false;
            this.errors = err.errors;
            this.$snotify.error("Oops something went wrong");
          });
    },
  }
}
</script>